/* eslint-disable max-len */
/* eslint-disable prefer-promise-reject-errors */
import axios from "axios";

export default {
  state: {
    orderId: null,
    currentStep: 1,
    user: {},
    orders: [],
    pagination: {},
    orderDateFilter: null,
    filter: {
      selectedTypeId: null,
      code: "",
    },
    orderDetail: null,
  },
  mutations: {
    SET_ORDERS: (state, orders) => {
      state.orders = orders;
    },
    SET_ORDER_DETAIL: (state, orderDetail) => {
      state.orderDetail = orderDetail;
    },
    SET_ORDER_DETAIL_ID: (state, orderId) => {
      state.orderId = orderId;
    },
    SET_CURRENT_STEP: (state, step) => {
      state.currentStep = step;
    },
  },
  getters: {
    HISTORY_ORDERS: (state) => state.orders,
    HISTORY_ORDER_DETAIL: (state) => state.orderDetail,
    HISTORY_ORDER_DETAIL_ID: (state) => state.orderId,
    HISTORY_CURRENT_STEP: (state) => state.currentStep,
  },
  actions: {
    HISTORY_ORDERS: ({ commit }, orders) => {
      commit("SET_ORDERS", orders);
    },

    HISTORY_ORDER_DETAIL: ({ commit }, order) => {
      commit("SET_ORDER_DETAIL", order);
    },

    HISTORY_ORDER_DETAIL_ID: ({ commit }, orderId) => {
      commit("SET_ORDER_DETAIL_ID", orderId);
    },

    HISTORY_CURRENT_STEP: ({ commit }, currentStep) => {
      commit("SET_CURRENT_STEP", currentStep);
    },

    HISTORY_GET_ORDER_BY_ID: ({ rootState }, orderId) => {
      const { user } = rootState.userModule;

      return new Promise((resolve, reject) => {
        if (!user) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(true);
        } else {
          const url = `/clients/${user.id}/orders/${orderId}`;
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              const orderDetail = response.data.result;
              resolve(orderDetail);
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },

    HISTORY_GET_ORDER_CYT_BY_ID: ({ rootState }, orderId) => {
      const { user } = rootState.userModule;

      return new Promise((resolve, reject) => {
        if (!user) {
          reject(true);
        } else {
          const url = `/clients/${user.id}/compraTraeOrders/${orderId}`;
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              const orderDetail = response.data.result;
              resolve(orderDetail);
            })
            .catch((error) => {
              reject(new Error(error));
            });
        }
      });
    },

    DOWNLOAD_HISTORY_REPORT: ({ rootState }) => {
      const { user } = rootState.userModule;
      const baseUrl = `clients/${user.id}/orders/report`;
      // filters
      // const code = this.orderCodeFilter === null ? "" : this.orderCodeFilter;
      // var arrDates = this.orderDateFilter
      //   ? this.orderDateFilter.split(" - ")
      //   : [];
      // let rStart = arrDates.length > 0 ? arrDates[0] : null;
      // let rEnd = arrDates.length > 1 ? arrDates[1] : rStart;
      // let start = rStart === null ? "" : rStart;
      // let end = rEnd === null ? "" : rEnd;

      const code = "";
      const start = "";
      const end = "";

      const url = `${baseUrl}?code=${code}&startDate=${start}&endDate=${end}`;

      axios
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Reporte de Ordenes.xlsx";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    },

    HISTORY_FETCH_ORDERS: ({ state, rootState }, payload, pagediff) => {
      let orders = [];
      const { user } = rootState.userModule;

      const codeBase64 = "";
      const type = payload?.serviceType ?? "";
      const start = payload?.startDate ?? "";
      const end = payload?.endDate ?? "";

      if (Number.isNaN(pagediff)) state.pagination.page = 1;
      else state.pagination.page += pagediff || 0;

      state.pagination.hasNextPage = false;
      state.pagination.hasPrevPage = false;
      state.pagination.totalItems = 0;
      state.pagination.totalPages = 0;

      const pageSize = 50;

      // var arrDates = state.orderDateFilter
      //   ? state.orderDateFilter.split(" - ")
      //   : [];

      // var start = arrDates.length > 0 ? arrDates[0] : "";
      // var end = arrDates.length > 1 ? arrDates[1] : start;

      return new Promise((resolve, reject) => {
        if (!user) {
          reject(true);
        } else {
          const url = `clients/${user.id}/orders?start=${start}&end=${end}&codeBase64=${codeBase64}&type=${type}&page=${state.pagination.page}&pageSize=${pageSize}`;
          axios
            .get(url, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              orders = response.data.result.list.map((item) => ({
                id: item.orderId,
                code: item.code,
                date: item.date,
                serviceType: item.type,
                status: item.state,
                statusClient: item.stateClient,
                // statusClient: $this.getClientState(item.StateClient),
                total: item.totalFare,
                section: item.section,
                haveTrackingNumber: item.haveTrackingNumber,
                scharfferFullName: item.scharfferFullName,
                scharfferPhone: item.scharfferPhone,
                subType: item.subType,
                originAddress: item.originAddress,
                destinationAddress: item.destinationAddress,
              }));

              state.pagination.hasNextPage = response.data.result.HasNextPage;
              state.pagination.hasPrevPage = response.data.result.HasPrevPage;
              state.pagination.totalItems = response.data.result.TotalCount;
              state.pagination.totalPages = response.data.result.TotalPages;

              state.orders = orders;
              resolve(orders);
            })
            .catch((error) => {
              reject(new Error(error));
            });
        }
      });
    },
  },
};
