/* eslint-disable max-len */
/* eslint-disable no-empty-pattern */
/* eslint-disable prefer-promise-reject-errors */
import axios from "axios";
import * as Sentry from "@sentry/vue";
import { getDefaultContact } from "../../services/contact";

export default {
  state: {
    contacts: {},
    favoriteAddresses: {},
    cards: {},
  },
  mutations: {
    SET_CARDS: (state, cards) => {
      state.cards = cards;
    },
    SET_CONTACTS: (state, contacts) => {
      state.contacts = contacts;
    },
    SET_FAVORITE_ADDRESSES: (state, addresses) => {
      state.favoriteAddresses = addresses.map(x => ({
        ...x,
        addressType: x.puntoScharffId ? 2 : 1,
      }))
    },
  },
  getters: {
    GENERAL_CARDS: (state) => state.cards,
    GENERAL_CONTACTS: (state) => state.contacts,
    GENERAL_FAVORITE_ADDRESSES: (state) => state.favoriteAddresses,
  },
  actions: {
    GENERAL_SET_CARDS: ({ commit }, cards) => {
      commit("SET_CARDS", cards);
    },
    GENERAL_SET_CONTACTS: ({ commit }, contacts) => {
      commit("SET_CONTACTS", contacts);
    },
    GET_FAVORITE_LOCATIONS: ({ commit, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .get(`clients/${user.id}/favoriteLocations?pageSize=1000`)
            .then((response) => {
              if (response.status === 200) {
                commit("SET_FAVORITE_ADDRESSES", response.data.result.list);
                resolve(response.data.result.list);
              } else {
                resolve(false);
              }
            })
            .catch(() => {
              reject(true);
            });
        }
      }),
    GET_CONTACTS: ({ rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .get(`contacts/${user.id}`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              console.log("🚀 ~ file: generalApiCalls.js:77 ~ newPromise ~ error", error)
              reject(error.response);
            });
        }
      }),
    GET_DEFAULT_CONTACT: ({ rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(false);
        } else {
          getDefaultContact()
            .then((response) => {
              console.log(
                "🚀 ~ file: generalApiCalls.js ~ line 96 ~ .then ~ response",
                response
              );
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    GET_DOCUMENT_TYPES: () =>
      new Promise((resolve, reject) => {
        axios
          .get(`feed/documentTypesValidation`)
          .then((response) => {
            if (response.status === 200) {
              const documentTypes = response.data.result;
              resolve(documentTypes);
            } else {
              reject(new Error("Error al obtener los tipos de documentos"));
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      }),
    GET_DISTRICTS: ({}, districts) =>
      new Promise((resolve, reject) => {
        axios
          .get(`feed/getDistrictsbyName/${districts}`)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      }),
    POST_CONTACTS: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .post(`contacts/${user.id}`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    PUT_CONTACTS: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .put(`contacts/${user.id}/${payload.contactId}`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    POST_FAVORITE_ADDRESS: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .post(`clients/${user.id}/favoriteLocations`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    PUT_FAVORITE_ADDRESS: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .put(
              `clients/${user.id}/favoriteLocations/${payload.favoriteLocationId}`,
              payload,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    POST_VALIDATE_COVERAGE: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          const data = {
            lat: payload.lat,
            lng: payload.lng,
          };

          axios
            .post(
              `orders/validateCoverage?isServiceProgrammed=${payload.isServiceProgrammed}&isServiceExpress=${payload.isServiceExpress}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    GET_NIUBIZ_SESSION_TOKEN: ({ rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        axios
          .get(`payments/${user.id}/session`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    NIUBIZ_VALIDATE_TOKEN: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        axios
          .get(
            `payments/validateCard?cardToken=${payload.transactionToken}&alias=${payload.alias}`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    GET_CARDS: ({ rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        axios
          .get(`clients/${user.id}/cards?frequent=false`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    POST_CARD: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .post(`clients/${user.id}/cards`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    GET_COUPON: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const weight = payload.weight ?? 0;
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .get(
              `coupons/validate?id=${user.id}&tipoPedidoId=${payload.serviceTypeId}&code=${payload.coupon}&peso=${weight}`,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    GET_COUPON_MULTI: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .get(
              `coupons/validateMultiProgrammed?id=${user.id}&tipoPedidoId=${payload.serviceTypeId}&code=${payload.coupon}&cantOrders=${payload.cantOrders}&lstPeso= ${payload.lstPeso}`,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    POST_CALCULATE_FARE: ({ rootState }, payload) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .post(`fare`, payload, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                tags: {
                  ErrorType: "Prioritize",
                },
              });
              reject(error.response);
            });
        }
      }),
    GET_UUID: ({ rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .get(`payments/generateUUID`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              Sentry.captureException(error, {
                tags: {
                  ErrorType: "Prioritize",
                },
              });
              reject(error.response);
            });
        }
      }),

    DELETE_CARD: ({ rootState }, cardId) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        if (!user) {
          reject(true);
        } else {
          axios
            .delete(`clients/${user.id}/cards/${cardId}`, {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response);
            });
        }
      }),
    // eslint-disable-next-line consistent-return
    GET_SCHARFF_POINT: ({ rootState }, districtId) => {
      const { user } = rootState.userModule;
      if (!user) {
        // eslint-disable-next-line no-undef
        return true;
      }
      return new Promise((resolve, reject) => {
        axios
          .get(`scharffpoints/${districtId}`)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(new Error(error));
          });
      });
    },
    GET_EXCEL_DEP_PRO_DIS: ({}, payload) =>
      new Promise((resolve, reject) => {
        const lastPath = `?departamento=${payload.departamento}&provincia=${payload.provincia}&distrito=${payload.distrito}`;
        axios
          .get(`feed/excelDepProDisByText${lastPath}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
    GET_EXCEL_TEMPLATE_PROGRAMMED: ({ rootState }) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .get(`multiOrderFile/generateProgrammedTemplate`, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    GET_EXCEL_TEMPLATE_PS_RECOJO: ({ rootState }) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .get(`multiOrderFile/generatePSRecojoTemplate`, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    GET_EXCEL_TEMPLATE_PS_ENVIO: ({ rootState }) => {
      const { user } = rootState.userModule;
      return new Promise((resolve, reject) => {
        axios
          .get(`multiOrderFile/generatePSEnvioTemplate`, {
            responseType: "blob",
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    GET_SCHARFF_POINT_BY_ID: ({}, id) =>
      new Promise((resolve, reject) => {
        axios
          .get(`scharffpoints/id/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response);
          });
      }),
  },
};
