/* eslint-disable import/no-cycle */
/* eslint-disable no-empty-pattern */
import axios from "axios";
import * as Sentry from "@sentry/vue";

export default {
  state: {
    progressNav: {
      progress: "25",
      stepsText: "Tipo de envío",
      title: "Envíos puerta a puerta",
      visiblePc: "true",
      showButtonBack: "true",
      helpModalId: "modalOnboardingProgrammedApp",
      step: 1,
      show: true,
    },
    order: {
      deliverDate: "",
      origin: {
        favoriteLocationId: 0,
        description: "",
        addressDetail: "",
        lat: null,
        lng: null,
        districtId: 0,
      },
      contactOrigin: null,
      Destination: [],
      voucher: null,
      card: null,
      fare: null,
      coupon: null,
    },
    map: null,
    file: null,
    indexDestination: 0,
    modals: {
      modalOrigin: null,
      modalDestination: null,
    },
    styles: {
      mapSaio: false,
      contentDiv: "",
      generalDiv: "",
      pmQuoter: false,
      // servicesWoMapDiv: "",
    },
  },
  mutations: {
    CLEAR_ORDER_DESTINATION: (state) => {
      state.order.Destination = [];
    },
    APPEND_ORDER_DESTINATION: (state, destination) => {
      state.order.Destination = destination;
    },
    SET_FILE: (state, file) => {
      state.file = file;
    },
    SET_STYLES_PROGRAMMED: (state, styles) => {
      state.styles = styles;
    },
    SET_PROGRESS_NAV: (state, progressNav) => {
      state.progressNav = progressNav;
    },
    SET_PROGRESS_NAV_ATTR: (state, attr) => {
      if (attr) {
        state.progressNav.progress =
          attr.progress ?? state.progressNav.progress;
        state.progressNav.stepsText =
          attr.stepsText ?? state.progressNav.stepsText;
        state.progressNav.title = attr.title ?? state.progressNav.title;
        state.progressNav.visiblePc =
          attr.visiblePc ?? state.progressNav.visiblePc;
        state.progressNav.showButtonBack =
          attr.showButtonBack ?? state.progressNav.showButtonBack;
        state.progressNav.helpModalId =
          attr.helpModalId ?? state.progressNav.helpModalId;
        state.progressNav.step = attr.step ?? state.progressNav.step;
        state.progressNav.show = attr.show ?? state.progressNav.show;
      }
    },
    SET_PROGRESS_NAV_VISIBLEPC: (state, attr) => {
      state.progressNav.visiblePc = attr;
    },
    SET_PROGRESS_NAV_VISIBILITY: (state, show) => {
      state.progressNav.show = show;
    },
    SET_PROGRESS_NAV_STEP: (state, step) => {
      const stringStep = step > 4 ? 4 : step;
      state.progressNav.stepsText = `Paso ${stringStep} de 4`;
      state.progressNav.title = "Envío Programado";
      state.progressNav.showButtonBack = "true";
      state.progressNav.helpModalId = "modalOnboardingProgrammedApp";

      if (step === 1) {
        state.progressNav.progress = "25";
        state.progressNav.visiblePc = "true";
        state.progressNav.stepsText = `Paso 1 de 4`;
        state.progressNav.step = 1;
        state.progressNav.show = true;
        state.styles.pmQuoter = false;
        state.styles.mapSaio = false;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_ONE;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_SERVICES_WO_MAP;
        // state.styles.pmQuoter = true;
      } else if (step === 2) {
        state.progressNav.progress = "50";
        state.progressNav.visiblePc = "true";
        state.progressNav.stepsText = `Paso 2 de 4`;
        state.progressNav.step = 2;
        state.progressNav.show = true;
        state.styles.pmQuoter = false;
        state.styles.mapSaio = false;
        state.styles.generalDiv =
          process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO_GENERAL;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO;
      } else if (step === 3) {
        state.progressNav.stepsText = `Paso 3 de 4`;
        state.progressNav.progress = "75";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 3;
        state.progressNav.show = true;

        state.styles.mapSaio = false;
        state.styles.generalDiv =
          process.env.VUE_APP_STYLE_PROGRAM_STEP_THREE_GENERAL;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_PROGRAM_STEP_TWO;
      } else if (step === 4) {
        state.progressNav.stepsText = `Paso 4 de 4`;
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "true";
        state.progressNav.step = 4;
        state.progressNav.show = true;
      } else {
        state.progressNav.progress = "100";
        state.progressNav.visiblePc = "false";
        state.progressNav.step = step;
        state.progressNav.show = false;
        state.styles.contentDiv = process.env.VUE_APP_STYLE_EXPRESS_STEP_W_BG;
        state.styles.generalDiv = process.env.VUE_APP_STYLE_HOME;
      }
    },
    SET_PROGRAMMED_MAP: (state, map) => {
      state.map = map;
    },
    SET_VOUCHER: (state, voucher) => {
      state.order.voucher = voucher;
    },
    SET_CARD: (state, voucher) => {
      state.order.card = voucher;
    },
    SET_ORDER: (state, order) => {
      state.order = order;
    },
    SET_ORDER_SET_DESTINATION: (state, destination) => {
      const dato = state.order.Destination.length;

      if (dato <= state.indexDestination) {
        state.order.Destination.push(destination.item);
      } else {
        state.order.Destination.splice(
          destination.index + 1,
          0,
          destination.item
        );
        state.order.Destination.splice(destination.index, 1);
      }
    },
    SET_ORDER_DELETE_DESTINATION: (state, indexDestination) => {
      state.order.Destination.splice(indexDestination, 1);
    },
    SET_INDEX_DESTINATION: (state, indexDestination) => {
      state.indexDestination = indexDestination;
    },
    // Order
    SET_CONTACT_ORIGIN: (state, contactOrigin) => {
      state.order.contactOrigin = contactOrigin;
    },
    SET_ORDER_DELIVER_DATE: (state, date) => {
      state.order.deliverDate = date;
    },
    SET_ADDRESS_ORIGIN: (state, address) => {
      state.order.origin.favoriteLocationId = address.favoriteLocationId;
      state.order.origin.name = address.name;
      state.order.origin.description = address.description;
      state.order.origin.additionalAddress = address.additionalAddress;
      state.order.origin.addressDetail = address.addressDetail;
      state.order.origin.lat = address.lat;
      state.order.origin.lng = address.lng;
      state.order.origin.departmentName = address.departmentName;
      state.order.origin.provinceName = address.provinceName;
      state.order.origin.districtName = address.districtName;

    },
    SET_ADDRESS_DISTRICT_ORIGIN: (state, districtId) => {
      state.order.origin.districtId = districtId;
    },
    SET_ORIGIN_ADDRESS_DETAIL: (state, districtId) => {
      state.order.origin.addressDetail = districtId;
    },

    SET_ORDER_FARE: (state, fare) => {
      state.order.fare = fare;
    },
    SET_COUPON: (state, coupon) => {
      state.order.coupon = coupon;
    },
  },
  getters: {
    PROGRAMMED_MAP: (state) => state.map,
    PROGRAMMED_FILE: (state) => state.file,
    PROGRAMMED_STYLES: (state) => state.styles,
    PROGRAMMED_PROGRESS_NAV: (state) => state.progressNav,
    PROGRAMMED_ORDER: (state) => state.order,
    PROGRAMMED_ORDER_FARE: (state) => state.order.fare,
    PROGRAMMED_ORDER_ORIGIN: (state) => state.order.origin,
    PROGRAMMED_ORDER_ORIGIN_FAVORITE_ID: (state) => state.order.origin.favoriteLocationId,
    PROGRAMMED_ORIGIN_CONTACT: (state) => state.order.contactOrigin,
    PROGRAMMED_ORDER_DELIVERY_DATE: (state) => state.order.deliverDate,
    /// Dont Use
    PROGRAMMED_VALIDATE_STEP_2: (state) => {
      try {
        if (state.order.contactOrigin === null)
          return "Debe escoger un contacto de quien envia";
        if (state.order.origin.description === "")
          return "Debe seleccionar una direccion valida";
        if (!state.order.origin.districtId.id)
          return "La dirección de recojo que ingresaste no tiene distrito. Puedes editarla y escoger un distrito de la lista. Alternativamente puedes crear una dirección nueva.";
        if (state.order.origin.description.length > 150)
          return `Parece que la <b>dirección</b> ah excedido el límite de 150 caracteres por ${state.order.origin.description.length - 150}. ¿Podrías reducirlo un poco?`;
        if (state.order.origin.addressDetail.length > 150)
          return `Parece que la <b>referencia</b> ah excedido el límite de 150 caracteres por ${state.order.origin.addressDetail.length - 150}. ¿Podrías reducirlo un poco?`
        return "ok";
      } catch (e) {
        console.log({e})
      }
    },
    PROGRAMMED_INDEX_DESTINATION: (state) => state.indexDestination,
    PROGRAMMED_ORDER_DESTINATIONN: (state) => state.order.Destination,
    PROGRAMMED_ORDER_STEP: (state) => state.progressNav.step,
    PROGRAMMED_COUPON: (state) => state.order.coupon,
    PROGRAMMED_TIP: (state) => state.order.tip,
    PROGRAMMED_CARD: (state) => state.order.card,
    PROGRAMMED_VOUCHER: (state) => state.order.voucher,
    PROGRAMMED_FARE: (state) => state.order.fare,
  },
  actions: {
    PROGRAMMED_MAP_SET: ({ commit }, map) => {
      commit("SET_PROGRAMMED_MAP", map);
    },
    PROGRAMMED_FILE: ({ commit }, file) => {
      commit("SET_FILE", file);
    },
    PROGRAMMED_PROGRESS_NAV_ATTR: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_ATTR", attr);
    },
    PROGRAMMED_PROGRESS_NAV_VISIBLEPC: ({ commit }, attr) => {
      commit("SET_PROGRESS_NAV_VISIBLEPC", attr);
    },
    PROGRAMMED_PROGRESS_NAV_VISIBILITY: ({ commit }, show) => {
      commit("SET_PROGRESS_NAV_VISIBILITY", show);
    },
    PROGRAMMED_PROGRESS_NAV_STEP: ({ commit }, step) => {
      commit("SET_PROGRESS_NAV_STEP", step);
    },
    PROGRAMMED_ORDER_DELIVER_DATE: ({ commit }, date) => {
      commit("SET_ORDER_DELIVER_DATE", date);
    },

    // Set Order

    PROGRAMMED_ORDER_ORIGIN_CONTACT: ({ commit }, originContact) => {
      commit("SET_CONTACT_ORIGIN", originContact);
    },

    PROGRAMMED_ORDER_ORIGIN_ADDRESS: ({ commit }, address) => {
      commit("SET_ADDRESS_ORIGIN", address);
    },

    PROGRAMMED_ORDER_ORIGIN_DISTRICT: ({ commit }, districtId) => {
      commit("SET_ADDRESS_DISTRICT_ORIGIN", districtId);
    },
    PROGRAMMED_ORDER_ORIGIN_ADDRESS_DETAIL: ({ commit }, districtId) => {
      commit("SET_ORIGIN_ADDRESS_DETAIL", districtId);
    },

    PROGRAMMED_ORDER_SET_DESTINATION: ({ commit }, destination) => {
      commit("SET_ORDER_SET_DESTINATION", destination);
    },

    PROGRAMMED_ORDER_DELETE_DESTINATION: ({ commit }, indexDestination) => {
      commit("SET_ORDER_DELETE_DESTINATION", indexDestination);
    },

    PROGRAMMED_ORDER_SET_INDEX_DESTINATION: ({ commit }, index) => {
      commit("SET_INDEX_DESTINATION", index);
    },
    // Servicios
    PROGRAMMED_CALCULATE_FARE: ({}, fare) =>
      new Promise((resolve, reject) => {
        axios
          .post(`fare`, fare)
          .then((response) => {
            if (response.status === 200) {
              resolve(response.data.result);
            } else if (response.status === 204) {
              reject(new Error("Faltan datos por completar"));
            } else {
              reject(new Error("Ocurrió un error en el servidor"));
            }
          })
          .catch((error) => {
            Sentry.captureException(error, {
              tags: {
                ErrorType: "Prioritize",
              },
            });
            reject(new Error(error));
          });
      }),

    PROGRAMMED_VOUCHER: ({ commit }, voucher) => {
      commit("SET_VOUCHER", voucher);
    },
    PROGRAMMED_CARD: ({ commit }, voucher) => {
      commit("SET_CARD", voucher);
    },
    PROGRAMMED_FARE: ({ commit }, fare) => {
      commit("SET_ORDER_FARE", fare);
    },
    PROGRAMMED_COUPON: ({ commit }, coupon) => {
      commit("SET_COUPON", coupon);
    },
    PROGRAMMED_CREATE_FARE: ({ state, rootState }) =>
      new Promise((resolve, reject) => {
        const { user } = rootState.userModule;
        const { uuid } = rootState.userModule;

        if (!user) {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject(true);
        } else {
          const { order } = state;
          const postMultiOrderRequestModel = {
            orders: [],
            userId: user.id,
            isSubscriptionUsed: true,
            uuid,
          };

          order.Destination.forEach((dest) => {
            const originLat = order.origin.lat ? order.origin.lat : 0;
            const originLng = order.origin.lng ? order.origin.lng : 0;
            const addresses = [];
            const originLocation = {
              direccion: order.origin.description,
              orden: 1,
              tipo: 1,
              districtId: order.origin.districtId.id,
              contacto: order.contactOrigin.name,
              celular: order.contactOrigin.phone,
              correoContacto: order.contactOrigin.email,
              DNIContacto: order.contactOrigin.documentNumber,
              location: {
                lat: originLat,
                lng: originLng,
              },
              detalle: order.origin.addressDetail,
            };
            addresses.push(originLocation);

            const bill = {
              ruc:
                order.voucher?.typeVoucher === "Factura"
                  ? order.voucher.documentNumber
                  : null,
              businessName:
                order.voucher?.typeVoucher === "Factura"
                  ? order.voucher.name
                  : null,
              contactName:
                order.voucher?.typeVoucher === "Factura"
                  ? order.voucher.name
                  : null,
              contactEmail:
                order.voucher?.typeVoucher === "Factura"
                  ? order.voucher.email
                  : null,
              dni:
                order.voucher?.typeVoucher === "Boleta"
                  ? order.voucher.documentNumber
                  : null,
              name:
                order.voucher?.typeVoucher === "Boleta"
                  ? order.voucher.name
                  : null,
              email:
                order.voucher?.typeVoucher === "Boleta"
                  ? order.voucher.email
                  : null,
              cellphone: order.voucher?.phone,
            };

            const orderRequest = {
              programmedServiceDate: order.deliverDate,
              packageValue: dest.productValue,
              packageDescription: dest.description,
              packageWidth: dest.packageSize.width,
              packageHeight: dest.packageSize.height,
              packageDeep: dest.packageSize.lengths,
              packageWeight: dest.packageSize.weight,
              packageSize:
                dest.packageSize.type === "Pequeno" ||
                dest.packageSize.type === "Pequeño"
                  ? "Pequeño"
                  : dest.packageSize.type,
              serviceTypeId: 2, // refactor
              propina: null,
              isServiceExpress: false,
              isServiceProgrammed: true,
              addresses,
              isReturnOrigin: false, // todo
              isInsuranceAccepted: false, // todo,
              bill,
              coupon: dest.coupon ? dest.coupon : null,
              creditCardId: !user.esUsuarioProgramado
                ? order?.card?.cardId
                : null,
              fragil: dest.fragil ?? dest.isFragil,
              secondAttemptInPS: dest.secondAttemptInPS,
            };
            const destLat = dest.destinationAddress.lat
              ? dest.destinationAddress.lat
              : 0;
            const destLng = dest.destinationAddress.lng
              ? dest.destinationAddress.lng
              : 0;

            addresses.push({
              direccion: dest.destinationAddress.description,
              orden: 2,
              tipo: 2,
              districtId: dest.destinationAddress.districtId.id,
              contacto: dest.destinationAddress.contact.name,
              celular: dest.destinationAddress.contact.phone,
              correoContacto: dest.destinationAddress.contact.email,
              DNIContacto: dest.destinationAddress.contact.documentNumber,
              location: {
                lat: destLat,
                lng: destLng,
              },
              detalle: dest.destinationAddress.addressDetail,
            });

            postMultiOrderRequestModel.orders.push(orderRequest);
          });

          resolve(postMultiOrderRequestModel);

          // refactor bill

          // axios
          //   .post(
          //     `clients/${user.id}/multiorderProgrammed`,
          //     postMultiOrderRequestModel,
          //     {
          //       headers: {
          //         Authorization: `Bearer ${user.token}`,
          //       },
          //     }
          //   )
          //   .then((response) => {
          //     resolve(response);
          //   })
          //   .catch((error) => {
          //     reject(error.response);
          //   });
        }
      }),
    PROGRAMMED_PROCESS_EXCEL: ({ state, rootState }) =>
      new Promise((resolve, reject) => {
        try {
          const { user } = rootState.userModule;
          if (!user) {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject(true);
          } else {
            const formData = new FormData();
            state.file.forEach((f) => {
              formData.append("file", f);
              formData.append(
                "originDistrict",
                state.order.origin.districtId.id
              );
            });
            axios
              .post(`multiOrderFile/processProgrammed`, formData, {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                resolve(response);
              })
              .catch((error) => reject(error));
          }
        } catch (error) {
          reject(error);
        }
      }),
    PROGRAMMED_CLEAR_DESTINATION: ({ commit }, destination) => {
      commit("CLEAR_ORDER_DESTINATION", destination);
    },
    PROGRAMMED_APPEND_DESTINATION: ({ commit }, destination) => {
      commit("APPEND_ORDER_DESTINATION", destination);
    },
  },
};
